html{
  font-size: 15px;
}

.ButtonMenuSecondary{
  display: inline-flex;
  padding: 4px 12px 4px 0;
  align-items: flex-end;
  cursor: pointer;
  gap: 8px;
  background: #FFF;
  position: relative;
  text-decoration: none;
  .Text{
    color: #121212;
    leading-trim: both;
    text-edge: cap;
    font-family: Geist, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    text-decoration: none;
  }
}

.ButtonMenuSecondary:hover{
  padding: 4px 0 4px 12px;
  /*left: 12px;*/
  /*padding-left: 12px;*/
}

.ButtonMenuWithIcon{
  display: inline-flex;
  align-items: flex-start;
  gap: 16px;
  cursor: pointer;
  .Icon{
    display: flex;
    width: 36px;
    height: 36px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.05) 100%), #FFF;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  }
  .Text{
    display: flex;
    padding-top: 2px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    .Title{
      color: #121212;
      leading-trim: both;
      text-edge: cap;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
    }
    .Description{
      color: rgba(0, 0, 0, 0.40);
      leading-trim: both;
      text-edge: cap;
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.24px;
    }
  }
}

.ButtonMenuWithIcon:hover{
  .Icon{
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.15) 100%), #FFF;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  }
}


.ButtonPrimaryCTA {
  cursor: pointer;
  display: inline-flex;
  height: 3.5rem;
  padding: 0rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: #D12A35;
  box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.10) inset;
  transition: all 300ms ease-out;
  /*.Text{*/
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: Geist, sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.0225rem;
  /*}*/
}

.ButtonPrimaryCTA:hover {
  background: #AB222B;
}

.ButtonSecondaryCTA {
  cursor: pointer;
  display: inline-flex;
  height: 3.25rem;
  padding: 0rem 1.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: #FEFEFE;
  box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.04) inset;
  transition: all 300ms ease-out;
  .Text {
    color: black;
    leading-trim: both;
    text-edge: cap;
    font-family: Geist, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.02rem;
  }
  .Icons {
    width: 1.25rem;
    height: 1.25rem;
    .Vector{
      .Path{
        fill: #121212;
      }
    }
  }
}

.ButtonSecondaryCTA:hover {
  background: rgba(254, 254, 254, 0.80);
}

.ButtonSecondaryCTA.Outline{
  border: 1px solid #D4D4D4;
  background: #FEFEFE;
  box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.04) inset;
}

.ButtonSecondaryCTA.Outline:hover {
  border: 1px solid #D4D4D4;
  background: rgba(0, 0, 0, 0.05);
}

.ButtonSecondaryCTA.Red {
  background: #D12A35;
  box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.10) inset;
  .Text{
    color: white;
  }
  .Icons {
    .Vector{
      .Path{
        fill: white;
      }
    }
  }
}

.ButtonSecondaryCTA.Red:hover {
  background: #AB222B;
}


.ButtonMenu {
  cursor: pointer;
  display: inline-flex;
  height: 2.75rem;
  padding: 0rem 1.25rem;
  border: none;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: #FFF;
  transition: all 300ms ease-out;
  color: #121212;
  font-family: Geist, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.02rem;
}

.ButtonMenu:hover{
  background: rgba(0, 0, 0, 0.05);
}

.ButtonMenu.Outline{
  border: 1px solid #D4D4D4;
  background: #FEFEFE;
}

.ButtonMenu.Outline:hover{
  background: rgba(0, 0, 0, 0.05);
}

.ButtonFilters {
  cursor: pointer;
  display: inline-flex;
  height: 3rem;
  padding: 0rem 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  border: 1px solid #D4D4D4;
  background: #FFF;
  transition: all 300ms ease-out;
  .Icons{
    width: 1rem;
    height: 1rem;
  }
  .Text{
    color: #121212;
    font-family: Geist, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 1.3rem */
    letter-spacing: -0.02rem;
  }
}
.ButtonFilters:hover{
  background: rgba(0, 0, 0, 0.05);
}

.ButtonFilters.Selected{
  border: 1px solid #121212;
}

.ButtonFlat{
  cursor: pointer;
  display: inline-flex;
  height: 3.25rem;
  padding: 0rem 1.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  transition: all 300ms ease-out;
  border-radius: 0.25rem;

  leading-trim: both;
  text-edge: cap;
  font-family: Geist, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.04rem;
}

.ButtonFlat.Dark{
  background: #121212;
  color: white;
}

.ButtonFlat.Dark:hover{
  background: rgba(0, 0, 0, 0.60);
}

.ButtonFlat.Light{
  border: 1px solid #D4D4D4;
  background: #FFF;
  color: black;
}

.ButtonFlat.Light:hover{
  background: rgba(0, 0, 0, 0.05);
}

.ButtonFlat.Red{
  border-radius: 4px;
  background: #D12A35;
  color: white;
}

.ButtonFlat.Red:hover{
  background: #AB222B;
}

.ListButton {
  cursor: pointer;
  display: flex;
  width: 128px;
  height: 61px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  transition: all 300ms ease-out;
  .Frame {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    flex: 1 0 0;
    .Vector {
      width: 16px;
      height: 1.5px;
      fill: #D12A35;
      transition: all 300ms ease-out;
    }
    .Title {
      color: #121212;
      leading-trim: both;
      text-edge: cap;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Geist, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      /*line-height: 28px; !* 155.556% *!*/
      letter-spacing: -0.72px;
    }
  }
}
.ListButton:hover, .ListButton.active{
  background: rgba(0, 0, 0, 0.04);
  .Frame{
    /*align-items: center;*/
    .Vector{
      padding-bottom: 10px;
    }
  }
}

.TextButton{
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  transition: all 300ms ease-out;
  .Text{
    color: #121212;
    leading-trim: both;
    text-edge: cap;
    font-family: Geist, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 1.1375rem */
    letter-spacing: -0.0175rem;
  }
  .Vector{
    width: 0.9rem;
    height: 0.75rem;
    fill: #D12A35;
  }
}
.TextButton:hover{
  gap: 1.25rem;
}

.ContactButton{
  cursor: pointer;
  display: flex;
  width: 11.8125rem;
  height: 1.0625rem;
  /*justify-content: center;*/
  align-items: center;
  flex-shrink: 0;
  color: rgba(0, 0, 0, 0.80);
  leading-trim: both;
  text-edge: cap;
  font-family: "Geist Mono", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1.5rem */
  letter-spacing: -0.06rem;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}
.ContactButton:hover{
  text-decoration-line: none;
  text-decoration-style: unset;
  text-decoration-skip-ink: auto;
}

.FooterLink{
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  color: rgba(0, 0, 0, 0.60);
  text-decoration: none;
  leading-trim: both;
  text-edge: cap;
  font-family: Geist, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  letter-spacing: -0.28px;
}

.FooterLink:hover{
  color: #C42732;
}

.SocialButtons{
  cursor: pointer;
  width: 19.5px;
  height: 19.5px;
  flex-shrink: 0;
  .Vector{
    .Path{
      fill: black;
      fill-opacity: 0.6;
    }
  }
}

.SocialButtons:hover{
  svg{
    path{
      fill: #C42732;
      fill-opacity: 1;
    }
  }
}

.ScrollDown{
  cursor: pointer;
  display: flex;
  width: 2.75rem;
  height: 2.75rem;
  padding: 0.4375rem 0.375rem;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  /*animation: move-up-down 3s infinite ease-in-out;*/
  svg{
    position: relative;
    top: 0;
    animation: move-up-down 3s infinite ease-in-out;
  }
}

@keyframes move-up-down {
  0% {
    top: 0;
  }

  10% {
    top: 0;
  }
  50% {
    top: 20px;
  }

  60% {
    top: 20px;
  }
  100% {
    top: 0;
  }
}

.Label{
  display: inline-flex;
  height: 1.25rem;
  padding: 0rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  background: rgba(0, 0, 0, 0.04);
  width: fit-content;
  .text{
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Geist Mono", sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 0.975rem */
    letter-spacing: -0.015rem;
  }
}

.FAQSection{
  margin-bottom: 160px;
  width: 991px;
  height: 400px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  .leftContainer{
    width: 399px;
    /*height: 592px;*/
    flex-shrink: 0;
    position: relative;
    border-radius: 8px;
    background: #FFF;
    .Label{
      margin-bottom: 24px;
    }
    .title{
      color: #121212;
      leading-trim: both;
      text-edge: cap;
      font-family: Geist, sans-serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 36px */
      letter-spacing: -1.44px;
      margin-bottom: 44px;
      .decoration{
        background: linear-gradient(90deg, #C42732 63.43%, #F7313F 81.77%, #DE2C38 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .rightContainer{
    width:100%;
    /*display: flex;*/
    /*flex-direction: column;*/
    .accordion-line{
      width: 568px;
      height: 1px;
      background: rgba(0, 0, 0, 0.10);
      /*margin-bottom: 24px;*/
    }
  }
}

.productCard {
  display: flex;
  width: 991px;
  align-items: center;
  gap: 24px;
  margin: 44px 0;
  overflow: hidden;
  .title-picture{
    display: flex;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    flex-direction: row;
    .pictuceContainer{
      display: flex;
      width: 256px;
      height: 256px;
      align-items: flex-end;
      position: relative;
      align-self: center;
      .picture{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 4px;
        /*background: rgba(0, 0, 0, 0.04);*/
      }
      .picture.textile{
        background: rgba(0, 0, 0, 0.04);
      }
    }
    .title-colorSwatch{
      display: flex;
      width: 711px;
      padding: 2px 0px;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      transition: all 300ms ease-out;
      .title{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        color: #121212;
        font-family: Geist, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 23.4px */
        letter-spacing: -0.72px;
        text-wrap-mode: nowrap;
      }
      .kolory {
        display: flex;
        flex-direction: row;
        gap: 7px;
        .colorSwatch {
          width: 28px;
          height: 28px;
          border-radius: 4px;
          border: 1px solid #D4D4D4;
          background: #FFF;
          margin: 25px 0;
          position: relative;
          .clickable {
            cursor: pointer;
          }
          .mini-color-button.active {
            border: 1px solid #E31E24;
          }
        }
      }
      .propertyList{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        cursor: pointer;
        text-decoration: none;
        .line{
          width: 100%;
          height: 1px;
          background: rgba(0, 0, 0, 0.10);
        }
        .properties{
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;
          .name{
            color: #121212;
            /* Body */
            font-family: Geist, sans-serif;
            /*font-size: 14px;*/
            font-style: normal;
            font-weight: 400;
            line-height: 130%; /* 18.2px */
            letter-spacing: -0.42px;
          }
          .subProperties{
            width: 380px;
            color: rgba(0, 0, 0, 0.60);
            text-align: right;

            /* Body */
            font-family: Geist, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%; /* 18.2px */
            letter-spacing: -0.42px;
          }
        }
      }
    }
    .productCardArrow{
      display: flex;
      width: 68px;
      justify-content: center;
      align-items: flex-end;
      gap: 14px;
      align-self: stretch;
      .accordionLine{
        width: 1px;
        height: 256px;
        background: rgba(0, 0, 0, 0.10);
      }
      .arrowRight{
        display: flex;
        width: 54px;
        height: 54px;
        padding: 12px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        gap: 10px;
        flex-shrink: 0;
      }
    }
  }
}

.productCard:hover{
  .title-colorSwatch{
    width: 630px;
  }
}


.card {
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  .frame1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;
    align-self: stretch;
    .frame2{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 44px;
      align-self: stretch;
      .frame3{
        display: flex;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        .title{
          flex: 1 0 0;
          color: #121212;
          leading-trim: both;
          text-edge: cap;
          font-family: Geist, sans-serif;
          font-size: 20px;
          margin: 0;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 20px */
          letter-spacing: -0.8px;
        }
      }
      .text{
        align-self: stretch;
        color: rgba(0, 0, 0, 0.60);
        leading-trim: both;
        text-edge: cap;
        font-family: Geist, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 20.8px */
        letter-spacing: -0.64px;
      }
    }
  }
}

.SecondContactSection{
  display: flex;
  width: 776px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 52px;
  margin-bottom: 160px;
  .Text{
    align-self: stretch;
    color: #121212;
    leading-trim: both;
    text-edge: cap;
    font-family: Geist, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -1.28px;
    .decoration{
      background: linear-gradient(90deg, #C42732 0%, #F7313F 33.34%, #DE2C38 66.48%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .frame{
    display: flex;
    align-items: center;
    gap: 64px;
  }
}
